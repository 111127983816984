import * as React from 'react';
import { graphql, PageProps, Link } from 'gatsby';
import { styled } from 'linaria/react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import CommonLayout, { SubDirectorySection } from '../templates/CommonLayout';
import Title from '../components/Title';
import Logo from '../assets/svg/logo/info.imp.svg';
import { formatDate } from '../Utils';

const Item = styled.li`
  a:hover {
    background: rgba(255, 255, 255, 0.3);
    background-blend-mode: lighten;
  }
`;

const NewsItem = ({
  thumb,
  date,
  title,
  summary,
  index,
}: {
  thumb: string | undefined;
  date: string;
  title: string;
  summary: string;
  index: number;
}) => {
  const device = useBreakpoint();
  const isSmallViewer = device.m;

  return (
    <Item style={{ position: 'relative', width: '300px', margin: isSmallViewer ? '0 0 1.5em 0' : '0 2em 1.5em  0' }}>
      <div
        style={{
          background: 'var(--theme-blue)',
          width: '100%',
          height: '200px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Logo title={title} fill="var(--white)" style={{ height: '80%', marginLeft: '-3.4em' }} />
        {thumb && <img src={`${thumb}?w=500`} alt={title} style={{ display: 'block', position: 'absolute' }} />}
      </div>
      <div
        style={{
          background: 'var(--theme-brown)',
          padding: '1em',
          paddingBottom: '4em',
          height: '230px',
          overflow: 'hidden',
          fontFamily: 'var(--font-jp-sans)',
          fontWeight: 300,
        }}
      >
        <p>{date}</p>
        <h3 style={{ padding: '1em 0', fontFamily: 'var(--font-jp-serif)', fontSize: 20, fontWeight: 100 }}>{title}</h3>
        <p style={{ lineHeight: '1.5em' }}>
          <span dangerouslySetInnerHTML={{ __html: summary || '' }} />
          <span
            style={{
              width: '100%',
              height: '2em',
              position: 'absolute',
              bottom: 0,
              left: 0,
              background: 'linear-gradient(to top, var(--theme-brown), 60%, transparent)',
            }}
          />
        </p>
      </div>
      <Link
        to={`/news/${index}`}
        style={{
          display: 'block',
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
        }}
      />
    </Item>
  );
};

const NewsPage = ({ data }: PageProps<GatsbyTypes.Query>) => {
  const device = useBreakpoint();
  const isSmallViewer = device.m;

  const news = data.allMicrocmsNews.edges.map(item => item.node);

  return (
    <CommonLayout title="お知らせ一覧">
      <SubDirectorySection style={{ width: '100%', alignItems: isSmallViewer ? 'center' : 'flex-start' }}>
        <Title
          label="News"
          ruby="お知らせ"
          align="center"
          style={{ alignSelf: isSmallViewer ? 'center' : 'flex-start' }}
        />
        <ul
          style={{
            marginTop: '2em',
            display: 'flex',
            flexDirection: isSmallViewer ? 'column' : 'row',
            flexWrap: 'wrap',
            justifyContent: isSmallViewer ? 'center' : 'flex-start',
          }}
        >
          {news.map((item, index) => (
            <NewsItem
              thumb={item.image?.url}
              date={formatDate(item.publishedAt)}
              title={item.title || '最新ニュース'}
              summary={item.body || ''}
              key={item.id}
              index={index}
            />
          ))}
        </ul>
      </SubDirectorySection>
    </CommonLayout>
  );
};

export default NewsPage;

export const query = graphql`
  query {
    allMicrocmsNews(limit: 12, skip: 0, sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          publishedAt
          title
          body
          image {
            url
          }
        }
      }
    }
  }
`;
